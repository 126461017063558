export enum ERouterName {
  ELEMENT = 'element',
  PROJECT = 'project',
  HOME = 'home',
  LIVESTREAM = 'livestream',
  LIVING = 'living',
  WORKSPACE = 'workspace',
  MEMBERS = 'members',
  DEVICES = 'devices',
  TASK = 'task',

  PILOT = 'pilot-login',
  PILOT_HOME = 'pilot-home',
  PILOT_MEDIA = 'pilot-media',
  PILOT_LIVESHARE = 'pilot-liveshare',
}

export enum EStatusValue {
  CONNECTED = 'Connected',
  DISCONNECT = 'Disconnected',
  LIVING = 'Living',
}

export enum EComponentName {
  Thing = 'thing',
  Liveshare = 'liveshare',
  Api = 'api',
  Ws = 'ws',
  Map = 'map',
  Tsa = 'tsa',
  Media = 'media',
  Mission = 'mission',
}

export enum ELocalStorageKey {
  Username = 'username',
  WorkspaceId = 'workspace_id',
  Token = 'x-auth-token',
  PlatformName = 'platform_name',
  WorkspaceDesc = 'workspace_desc',
  Flag = 'flag',
  UserId = 'user_id',
  Device = 'device',
  GatewayOnline = 'gateway_online',
}

export enum EUserType {
  Web = 1,
  Pilot = 2,
}
