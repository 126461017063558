<template>
  <div class="realm-app">
    <router-view />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'

export default defineComponent({ name: 'App' })
</script>
<style lang="scss" scoped>
.realm-app {
  width: 100%;
  height: 100%;

  .map-wrapper {
    height: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
#realm-app {
  width: 100%;
  height: 100%
}
</style>
