import { createApp, ComponentCustomProperties, App as VueApp, Component } from 'vue'
import { createAuth0 } from '@auth0/auth0-vue'
import * as Sentry from '@sentry/vue'

import * as config from '/@/config'
import router from '/@/router'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $aMap: object
    $map: object
    $mouseTool: object
  }
}
let root: ComponentCustomProperties

let app: VueApp | null = null

export function createInstance(App: Component): VueApp {
  app = createApp(App)

  Sentry.init({
    app,
    dsn: config.SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/api\.realmalliance\.com\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

  app.use(
    createAuth0({
      domain: config.AUTH0_DOMAIN,
      clientId: config.AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin + '/auth-callback',
        audience: config.AUTH0_AUDIENCE,
      },
    }),
  )

  root = app.config.globalProperties as ComponentCustomProperties
  return app
}

export function getRoot(): ComponentCustomProperties {
  return root
}

export function getApp(): VueApp {
  return app
}
