import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { ERouterName } from '/@/types/index'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/' + ERouterName.PILOT,
  },

  // pilot
  {
    path: '/' + ERouterName.PILOT,
    name: ERouterName.PILOT,
    component: () => import('/@/pages/page-pilot/pilot-index.vue'),
  },
  {
    path: '/auth-callback',
    name: 'auth-callback',
    component: () => import('/@/pages/page-pilot/auth-callback.vue'),
  },
  {
    path: '/' + ERouterName.PILOT_HOME,
    component: () => import('/@/pages/page-pilot/pilot-home.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

// Add a global beforeEach navigation guard
router.beforeEach((to, from, next) => {
  // Log the route change
  console.log(`Navigating from ${from.fullPath} to ${to.fullPath}`)
  // Continue with the route navigation
  next()
})

export default router
